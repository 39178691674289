
import DigitizationContentComponent from "./editorDigitizationContent/DigitizationContentComponent";
import "../editorStyleSheet.css"
import BodyComponents from "./BodyComponents";
import PropTypes from "prop-types";



const EditorBodyComponent = ({handleFullScreen, isFullScreen, mediaPath, containerWidth, scale, setPageNumber, setNumPages,  forcePageNumber, setScale, numPages, pageNumber, setForcePageNumber, configWidth, updateConfigWidth, pages, sourceLanguage, targetLanguage, setCloseForcePageNumber}) => {
    return (
        <div className="digitizationEditorBody">
            <div className="body-component">
                <BodyComponents
                    mediaPath={mediaPath}   numPages={numPages}
                    setNumPages={setNumPages}  forcePageNumber={forcePageNumber}  scale={scale}
                    setScale={setScale}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    setForcePageNumber={setForcePageNumber}
                    containerWidth={containerWidth}
                    configWidth={configWidth}
                    setCloseForcePageNumber={setCloseForcePageNumber}
                    handleFullScreen={handleFullScreen}
                    isFullScreen={isFullScreen}

                />

                <DigitizationContentComponent
                    pageNumber={pageNumber}
                    configWidth={configWidth}
                    containerWidth={containerWidth}
                    updateConfigWidth={updateConfigWidth}
                    pages={pages}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                />
            </div>
        </div>
    )

}


EditorBodyComponent.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    configWidth: PropTypes.string,
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    scale: PropTypes.number,
    setPageNumber: PropTypes.func,
    forcePageNumber: PropTypes.number,
    setNumPages: PropTypes.func,
    setScale: PropTypes.func,
    setForcePageNumber: PropTypes.func,
    setCloseForcePageNumber: PropTypes.func,
    updateConfigWidth: PropTypes.func,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    pages: PropTypes.array,
    handleFullScreen: PropTypes.func,
    isFullScreen: PropTypes.bool
};
export default EditorBodyComponent;