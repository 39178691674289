import SoftBox from "../../components/SoftBox";
import SoftAvatar from "../../components/SoftAvatar";
import SoftTypography from "../../components/SoftTypography";
import PropTypes from "prop-types";

const AvatarComponent = ({image, name, email, bgColor, variant}) => {
    return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
            <SoftBox mr={2}>
                <SoftAvatar src={image} alt={name} size="sm" variant={variant} bgColor={bgColor}/>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                    {name}
                </SoftTypography>
                <SoftTypography variant="caption" color="secondary">
                    {email}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
}

AvatarComponent.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    bgColor: PropTypes.oneOf([
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    variant: PropTypes.string
}
export default AvatarComponent;