import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from "prop-types";

const SnackbarNotification = ({ notification, setNotification }) => {
    const handleClose = () => {
        setNotification({ show: false, snackbarMessage: '', severity: '' });
    };

    return (
        <Snackbar
            open={notification.show}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={notification.severity}
                variant="filled"
                sx={{
                    width: '100%',
                    color: 'white !important', // Text color to white
                    '& .MuiAlert-icon': {
                        color: 'white !important', // Icon color to white
                    },
                }}
            >
                {notification.snackbarMessage}
            </Alert>
        </Snackbar>
    );
};

SnackbarNotification.propTypes = {
    notification: PropTypes.shape({
        show: PropTypes.bool,
        severity: PropTypes.oneOf(["error","info","success","warning", ""]),
        snackbarMessage: PropTypes.string
    }),
    setNotification: PropTypes.func
}
export default SnackbarNotification;