import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Switch from 'react-switch';
import PropTypes from "prop-types";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const createGradient = (ctx, chartArea, colorStart, colorEnd) => {
    if (!chartArea) return colorStart;

    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(1, colorEnd);
    return gradient;
};

const GradientLineBarCharts = ({ elements }) => {
    const [chartType, setChartType] = useState('bar');

    const labels = elements.map(d => d.title);
    const pagesData = elements.map(d => d.page_count);
    const digitizationTimeData = elements.map(d => {
        if (!d.duration) return 0;
        const [hours, minutes, seconds] = d.duration.split(':').map(Number);
        return (hours * 60) + minutes + (seconds / 60);
    });

    const data = {
        labels,
        datasets: [
            {
                label: 'Number of Pages',
                data: pagesData,
                backgroundColor: (ctx) => createGradient(ctx.chart.ctx, ctx.chart.chartArea, "#f00084", "#833ab4"),
                borderColor: '#833ab4',
                borderWidth: 1,
                yAxisID: 'y',
                barPercentage: 0.2,
                borderRadius: { topLeft: 10, topRight: 10 },
            },
            {
                label: 'Digitization Time (minutes)',
                data: digitizationTimeData,
                backgroundColor: (ctx) => createGradient(ctx.chart.ctx, ctx.chart.chartArea, "#63b5e8", "#01708f"),
                borderColor: '#01708f',
                borderWidth: 1,
                yAxisID: 'y1',
                barPercentage: 0.2,
                borderRadius: { topLeft: 10, topRight: 10 },
            }
        ]
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                type: 'linear',
                position: 'left',
                beginAtZero: true,
                title: { display: true, text: 'Number of Pages' },
            },
            y1: {
                type: 'linear',
                position: 'right',
                beginAtZero: true,
                title: { display: true, text: 'Digitization Time (minutes)' },
                grid: { drawOnChartArea: false },
            },
            x: {
                title: { display: true, text: 'Articles' },
            },
        },
    };

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <h4 style={{margin: 0}}>Digitization Time Overview <span style={{fontSize: '14px'}}>
                    (Last 5 days)
                </span>
                </h4>
            </div>
            <div>
                <span style={{marginLeft: '5px', fontSize: '14px'}}>
                    {chartType === 'bar' ? 'Switch to Line Chart' : 'Switch to Bar Chart'}
                </span>
                <label style={{marginLeft: '10px'}}>
                    <Switch
                        onChange={() => setChartType(prevType => (prevType === 'bar' ? 'line' : 'bar'))}
                        checked={chartType === 'line'}
                        onColor="#f00084"
                        offColor="#888"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={10}
                        height={15}
                        width={30}
                    />
                </label>
            </div>
            {chartType === 'bar' ? (
                <Bar data={data} options={options}/>
            ) : (
                <Line data={data} options={options}/>
            )}
        </div>
    );
};

GradientLineBarCharts.propTypes = {
    elements: PropTypes.arrayOf(
        PropTypes.object
    )
};
export default GradientLineBarCharts;
