import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SidenavCollapse from "reusableComponents/Sidenav/SidenavCollapse";

import SidenavRoot from "reusableComponents/Sidenav/SidenavRoot";
import sidenavLogoLabel from "reusableComponents/Sidenav/styles/sidenav";

import { useSoftUIController } from "context";
import { setMiniSidenav } from "../../store/actions/app-actions";
import Box from "@mui/material/Box";

function Sidenav({ color = "info", brand = "", brandName, routes, ...rest }) {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, transparentSidenav } = controller;
    const location = useLocation();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const [isHovered, setIsHovered] = useState(false); // State to track hover status

    const toggleSidenav = () => {
        setMiniSidenav(dispatch, !miniSidenav);
    };

    useEffect(() => {
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        window.addEventListener("resize", handleMiniSidenav);
        handleMiniSidenav();

        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch]);

    const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
        let returnValue;

        if (type === "collapse") {
            returnValue = href ? (
                <Link
                    href={href}
                    key={key}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: "none" }}
                >
                    <SidenavCollapse
                        color={color}
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        noCollapse={noCollapse}
                    />
                </Link>
            ) : (
                <NavLink to={route} key={key}>
                    <SidenavCollapse
                        color={color}
                        key={key}
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        noCollapse={noCollapse}
                    />
                </NavLink>
            );
        } else if (type === "title") {
            returnValue = (
                <SoftTypography
                    key={key}
                    display="block"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="uppercase"
                    opacity={0.6}
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                >
                    {title}
                </SoftTypography>
            );
        } else if (type === "divider") {
            returnValue = <Divider key={key} />;
        }

        return returnValue;
    });

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{ transparentSidenav, miniSidenav }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <SoftBox pt={3} pb={1} px={4} textAlign="center">
                <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    position="absolute"
                    top={5}
                    right={0}
                    p={0}
                    sx={{ cursor: "pointer" }}
                    onClick={toggleSidenav}
                >
                    <Box
                        sx={{
                            marginTop: '4px',
                            marginRight: '6px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: isHovered ? 'flex' : 'none',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: 2,
                            transition: 'background-color 0.3s',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            }
                        }}
                    >
                        <Icon>{miniSidenav ? 'chevron_right' : 'chevron_left'}</Icon>
                    </Box>
                </SoftBox>
                <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
                    <SoftBox component="img" src={brand} alt="Yai4Edu" width={!miniSidenav ? "7rem" : "2rem"} />
                    {!miniSidenav && (
                        <SoftBox
                            width={!brandName && "100%"}
                            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                        >
                        </SoftBox>
                    )}
                </SoftBox>
            </SoftBox>
            <Divider />
            <List>{renderRoutes}</List>
        </SidenavRoot>
    );
}

Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
