import axios from 'axios';
import {FREE_AGENT, LIBRARY_ADMIN_ROLE, SUPER_ADMIN_ROLE} from "./utlis/constant";
const baseURL = process.env.REACT_APP_API_GATEWAY;

const axiosClient = axios.create({
    baseURL: `${baseURL}/api/`,
});

export const login = (email, password, role) => {
    return axiosClient.post('login', { email, password, role }); 
};
  



export const logout = () => {

    return axiosClient.post('logout');
};

export const acceptEula = () => {

    return axiosClient.post('/accept-eula');
};
export const register = (formData) => {
    const { name, email, password, departments, isFreeAgent } = formData;

        return axiosClient.post('register', {
            name,
            email,
            password,
            departments: departments,
            isFreeAgent: isFreeAgent
        });
};
export const addLibrarian = async (formData) => {
    const { name, email, password, department, address, phone } = formData;

    return axiosClient.post('add_librarian', {
        name,
        email,
        password,
        department_id: department,
        address,
        phone
    });
};
export const addLibrarianAdmin = async (formData) => {
    const { name, email, password, university, address, phone } = formData;

    return axiosClient.post('add_library-admin', {
        name,
        email,
        password,
        university_id: university,
        address,
        phone
    });
};

const getToken = () => localStorage.getItem('token');

axiosClient.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {

        if (error.response) {

            if (error.response.status === 401 && error.config.url != "login") {

                localStorage.removeItem('token');
                window.location.href = "/authentication/sign-in";
            } else if (error.response.status === 403) {

                window.location.href = "/dashboard";
            }
        } else {
            console.error('An error occurred:');
        }
        return Promise.reject(error);
    }
);

export const getDigitizationCount = () => {
    return  axiosClient.get(`digitization-count`)
};


export const getResearchersActivity = (params) => {
    return axiosClient.get('researchers-activity', { params });
};

export const activateResearchers = (ids, data) => {
    return  axiosClient.put(`activate-users/${ids}`, data)
};

export const getResearchersList = (params) => {
    return axiosClient.get('researchers' + params );
};

export const getUsersList = (params) => {
    return axiosClient.get('users', { params });
};

export const getDepartementsList = (params = null) => {

    return axiosClient.get('departments' + (params ?? "") );
};
export const getLibrarianAdminsList = (params) => {
    return axiosClient.get('library-admins' + params );
};
export const getAnonymousUsersList = (params) => {
    return axiosClient.get('anonymous-users' + params );
};
export const getLibrariansList = (params) => {
    return axiosClient.get('librarians' + params );
};

export const getProfile = (role) => {

    if (role === SUPER_ADMIN_ROLE || role === LIBRARY_ADMIN_ROLE) {

        return axiosClient.get('admin-profile');
    } else {

        return axiosClient.get('user-profile');
    }
};
export const updateProfile = (id, updatedData, role) => {

    if (role === SUPER_ADMIN_ROLE || role === LIBRARY_ADMIN_ROLE) {

        return axiosClient.put(`/admins/${id}`, updatedData);
    } else {

        return axiosClient.put(`/users/${id}`, updatedData);
    }
};
export const getUniversities = (params = '') => {
  return axiosClient.get(`universities${params}`)
};

export const getPublicUniversities = () => {

    return axiosClient.get('public/universities')
};
export const AddUniversities = (data) => {
    return axiosClient.post('universities', data)
};

export const EditUniversities = (id, updatedData) => {
    return axiosClient.put(`/universities/${id}`, updatedData)
};
export const DeleteUniversity = (id) => {
    return axiosClient.delete(`/universities/${id}`)
};


export const addDepartment = (data) => {
    return axiosClient.post('departments', data)
};
export const updateDepartment = (id, updatedData) => {
    return axiosClient.put(`/departments/${id}`, updatedData);
};
export const getDigitizations = (params) => {

    return axiosClient.get('digitizations' + params);
};

export const getFreeAgentDigitizations = (params) => {
    return axiosClient.get('free-agent-digitizations' + params);
};

export const getUserDocuments = (params) => {

    return axiosClient.get('my-documents' + params);
};

export const getOrdersList = (params= "") => {

    return axiosClient.get('payment-list' + params);
};

export const getArchivesByPeriod = (params) => {

    return axiosClient.get('digitizations-by-period' + params);
};

export const getDigitizationsReport = (params = '') => {
    return axiosClient.get(`digitizations-statistics-by-period${params}`);
};

export const exportDigitizationsReport = (params = '') => {
    return axiosClient.get(`digitizations-statistics-export${params}`);
};

export const getArchivesById = (id) => {
    return axiosClient.get(`digitizations/${id}`);
};

export const getSubjects = () => {
    return axiosClient.get('subjects')
};

export const addSubject = (data) => {
    return axiosClient.post('subjects', data)
};

export const digitize = (data) => {
    return axiosClient.post('digitizations', data)
};
export const createArticle = (data) => {
    return axiosClient.post('articles', data)
};

export const deleteArticle = (article_id) => {
    return axiosClient.delete(`/digitizations/${article_id}`);
};


export const forgotPassword = (email) => {
    return axiosClient.post('password-reset-request', { email });
    
};



export const UpdatePassword = (token, new_password, confirm_password) => {
    return axiosClient.post('password-reset-confirm', { 
      token, 
      new_password, 
      confirm_password 
})};

export const getStatistics = () => {
    return axiosClient.get('statistics');
};
export const checkDuplicatedArticles = (params) => {
    return axiosClient.get('check-duplicated-articles', params);
};

export const getDepartmentsByUniversity = (university_id) => {
    return axiosClient.get(`public/departments?university_id=${university_id}`);
};
export const createOrder = (articleIds, paymentSource) => {
    return axiosClient.post(`/create-order`, {article_ids: articleIds, payment_source: paymentSource});
};

export const captureOrder = (orderId) => {
    return axiosClient.post(`/capture-order/${orderId}`);
};

export const deleteLibrarianAdmin = (id) => {
    return axiosClient.delete(`/librarian-admins/${id}`);
};
