import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import StepConnector from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    '&.Mui-active .MuiStepConnector-line': {
        borderColor: '#4caf50',
    },
    '&.Mui-completed .MuiStepConnector-line': {
        borderColor: '#4caf50',
    },
    '& .MuiStepConnector-line': {
        borderColor: '#e0e0e0',
    },
}));

const CustomStepIcon = ({ active, completed }) => {
    return (
        <div style={{ position: 'relative', width: 20, height: 20, zIndex: 1 }}>
            {completed ? (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        backgroundColor: '#4caf50',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <CheckIcon style={{ color: 'white', fontSize: 13 }} />
                </div>
            ) : active ? (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        backgroundColor: '#e0e0e0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <CircularProgress
                        size={13}
                        thickness={3}
                        style={{
                            color: '#4caf50',
                            position: 'absolute',
                        }}
                    />
                </div>
            ) : (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        backgroundColor: '#e0e0e0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                />
            )}
        </div>
    );
};

CustomStepIcon.propTypes = {
    active: PropTypes.bool.isRequired,
    completed: PropTypes.bool.isRequired,
};

const StepperComponent = ({ activeStep, steps }) => {
    return (
        <Box sx={{ justifyContent: 'center', width: '60%', alignItems: 'center' }}>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<CustomStepConnector />}
                sx={{ margin: '20px 0 0 0' }}
            >
                {steps.map((label, index) => (
                    <Step key={label} completed={index < activeStep}>
                        <StepLabel
                            StepIconComponent={CustomStepIcon}
                            sx={{ fontSize: '12px', '& .MuiStepLabel-label': { fontSize: '0.8rem' } }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

StepperComponent.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StepperComponent;
