import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import signUpBgImage from "assets/images/curved-images/login.png";
import logo from "assets/images/logo.png";
import { login } from "../../../axios-client";
import { useSoftUIController } from "../../../context";
import { setToken, setUserInfo } from "../../../store/actions/app-actions";
import Alert from "@mui/material/Alert";
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FaUserTie } from "react-icons/fa6";


const RoleButton = styled(SoftButton)(({ selected }) => ({
  border: selected ? '2px solid #cb0c9f' : '2px solid #3a416ff2',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100px',
  height: '100px',
  color: selected ? '#cb0c9f' : '#3a416ff2',
  '& .check-icon': {
    display: selected ? 'block' : 'none',
    position: 'absolute',
    color:'white',
    left: '-5px',
    top: '0px',
    transform: 'translateY(-60%)',
    backgroundColor:'#cb0c9f',
    borderRadius: '50%',
  },
  '& svg': {
    fontSize: '86px',
  },
}));

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [role, setRole] = useState("free-agent");
  const [controller, dispatch] = useSoftUIController();
  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    setErrorMessage(null);
  }, [email, password]);

  const handleLogin = (event) => {
    event.preventDefault();
    login(email, password, role)
      .then((response) => {
        setUserInfo(dispatch, response?.data);
        setToken(dispatch, response.data?.access_token);
        localStorage.setItem("token", response.data?.access_token);
        navigate('/dashboard');
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('An error occurred. Please try again later.');
        }
        console.error(error);
      });
  };

  const handleRoleSelect = (selectedRole) => {
    setRole(selectedRole);
  };
  return (
    <CoverLayout
      title="Welcome back"
      description="Please select your role"
      image={signUpBgImage}
      logo={logo}
    >
      {errorMessage && <Alert severity="error" onClose={() => setErrorMessage(null)}>{errorMessage}</Alert>}

      <SoftBox component="form" role="form" onSubmit={handleLogin}>
          <SoftBox display="flex" justifyContent="space-around" mb={3}>
              <RoleButton
                  variant="outlined"
                  selected={role === "admin"}
                  onClick={() => handleRoleSelect("admin")}
              >
                  <CheckIcon className="check-icon"/>
                  <FontAwesomeIcon icon={faUserShield} style={{marginBottom: '8px', fontSize: '100px'}}/>
                  Admin
              </RoleButton>
              <RoleButton
                  variant="outlined"
                  selected={role === "user"}
                  onClick={() => handleRoleSelect("user")}
              >
                  <CheckIcon className="check-icon"/>
                  <FontAwesomeIcon icon={faUser} style={{marginBottom: '8px'}}/>
                  User
              </RoleButton>
              <RoleButton
                  variant="outlined"
                  selected={role === "free-agent"}
                  onClick={() => handleRoleSelect("free-agent")}
              >
                  <CheckIcon className="check-icon"/>
                  <FaUserTie style={{marginBottom: '8px'}}/>
                  Free Agent
              </RoleButton>
          </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </SoftBox>

        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
          <SoftBox display="flex" alignItems="center">
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;Remember me
            </SoftTypography>
          </SoftBox>
          {/* Forget password link */}
          <SoftTypography
            variant="button"
            fontWeight="regular"
            to="/authentication/reset-password"
            onClick={() => navigate("/authentication/reset-password")}
            sx={{ cursor: "pointer", userSelect: "none", color: "primary.main" }}
          >
            Forget password?
          </SoftTypography>
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="primary" fullWidth type="submit">
            Sign in
          </SoftButton>
        </SoftBox>

        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
