export const SET_USERINFO = "SET_USERINFO";
export const SET_TOKEN = "SET_TOKEN";
export const SET_EULA_ACCEPTED = "SET_EULA_ACCEPTED";
export const SET_PICTURE = "SET_PICTURE";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
export const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
export const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
export const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
export const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
export const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
export const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
export const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
export const setUserInfo = (dispatch, value) => dispatch({ type: SET_USERINFO, value });
export const setPicture = (dispatch, value) => dispatch({ type: SET_PICTURE, value });
export const setProfileData = (dispatch, value) => dispatch({ type: SET_PROFILE_DATA, value });
export const setToken = (dispatch, value) => dispatch({ type: SET_TOKEN, value });
export const setEulaAccepted = (dispatch, value) => dispatch({ type: SET_EULA_ACCEPTED, value });