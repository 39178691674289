/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {useState} from "react";

// Images
import dashboard from "assets/images/shapes/dashboard.png";
import rocketWhite from "assets/images/illustrations/rocket-white.png";

function BuildByDevelopers() {
    const [isExpanded, setIsExpanded] = useState(false);


    const fullText = `Yai4Edu provides researchers with innovative tools to streamline article digitization, making it easier to extract insights, organize knowledge, and unlock new opportunities for learning. Upload your documents and let our state-of-the-art AI tools do the heavy lifting—whether it's text extraction, translation, or content structuring, Yai4Edu is here to support your research and educational needs. Get started today and experience seamless digitization with Yai4Edu!`;


    const shortText = `Yai4Edu provides researchers with innovative tools to streamline article digitization, making it easier to extract insights, organize knowledge, and unlock new opportunities for learning.`;


    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Card>
            <SoftBox p={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <SoftBox display="flex" flexDirection="column" height="100%">
                            <SoftBox pt={1} mb={0.5}>
                                <SoftTypography variant="body2" color="text" fontWeight="medium">
                                    Getting Started
                                </SoftTypography>
                            </SoftBox>
                            <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                                Yai4Edu
                            </SoftTypography>
                            <SoftBox mb={6}>
                                <SoftTypography
                                    variant="body2"
                                    color="text"
                                    // Apply "justify" alignment when expanded
                                    sx={{
                                        textAlign: isExpanded ? "justify" : "left",
                                    }}
                                >
                                    {/* Show full text if expanded, else show short text */}
                                    {isExpanded ? fullText : shortText}
                                </SoftTypography>
                            </SoftBox>
                            <SoftTypography
                                component="a"
                                href="#"
                                variant="button"
                                color="text"
                                fontWeight="medium"
                                sx={{
                                    mt: "auto",
                                    mr: "auto",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    "& .material-icons-round": {
                                        fontSize: "1.125rem",
                                        transform: `translate(2px, -0.5px)`,
                                        transition: `transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)`,
                                    },
                                    "&:hover .material-icons-round, &:focus  .material-icons-round": {
                                        transform: `translate(6px, -0.5px)`,
                                    },
                                }}
                                onClick={toggleReadMore}
                            >

                                {isExpanded ? "Show Less" : "Read More"}
                                <Icon sx={{ fontWeight: "bold" }}>
                                    {isExpanded ? "arrow_upward" : "arrow_forward"}
                                </Icon>
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
                        <SoftBox
                            height="100%"
                            display="grid"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="lg"
                            variant="gradient"
                        >
                            <SoftBox
                                component="img"
                                src={dashboard}
                                alt="waves"
                                display="block"
                                position="absolute"
                                left={0}
                                width="200px"
                                height="200px"
                                borderRadius={10}
                            />
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>
        </Card>
    );
}

export default BuildByDevelopers;