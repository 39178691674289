import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MdEmail, MdLock } from "react-icons/md";
import SoftButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";
import {updateAdminProfile, updateProfile} from "../../axios-client";
import CircularProgress from '@mui/material/CircularProgress';
import {LIBRARIAN_ROLE, LIBRARY_ADMIN_ROLE, RESEARCHER_ROLE} from "../../utlis/constant";

const ResetPasswordDialog = ({ open, onClose, onLibrarianAdded, librarianData, isSuperAdmin }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isActivating, setIsActivating] = useState(false);

    useEffect(() => {
        if (open && librarianData) {
            setFormData({
                email: librarianData.email || '',
                password: '',
            });
        }
    }, [open, librarianData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const validateForm = () => {
        let formErrors = {};
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordPattern.test(formData.password)) {
            formErrors.password = "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character";
        }

        return formErrors;
    };

    const handleSubmit = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('password', formData.password);
            setIsActivating(true)
            if (isSuperAdmin) {
                updateProfile(librarianData.id, formDataToSend, LIBRARY_ADMIN_ROLE).then(
                    response => {
                        setIsActivating(false)
                        onLibrarianAdded();
                        onClose();
                    }
                ).catch(error  => {
                    setSnackbar({ open: true, message: 'Failed to update password', severity: 'error' });

                })
            } else {
                updateProfile(librarianData.id, formDataToSend, LIBRARIAN_ROLE).then(
                    response => {
                        setIsActivating(false)
                        onLibrarianAdded();
                        onClose();
                    }
                ).catch(error => {
                    setSnackbar({ open: true, message: 'Failed to update password', severity: 'error' });
                })
            }
        } else {
            setErrors(formErrors);
            setSnackbar({ open: true, message: 'Please fill out all required fields', severity: 'error' });
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle
                id="form-dialog-title"
                sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
                Reset Librarian Password
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        size: 10
                    }}
                />
            </DialogTitle>
            <Divider/>
            <DialogContent>

                <label htmlFor="email" className="label-style">User</label>
                <div style={inputContainerStyle}>
                    <MdEmail className="icon-style"/>
                    <input type="text" id="email" name="email" style={inputStyle}
                           value={formData.email}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}
                    disabled={true}/>
                </div>
                {errors.email && <p style={{color: 'red', fontSize: "12px"}}>{errors.email}</p>}
                <label htmlFor="password" className="label-style">New Password</label>
                <div style={{ ...inputContainerStyle, position: 'relative' }}>
                    <MdLock className="icon-style"/>
                    <input type={showPassword ? "text" : "password"}
                           id="password" name="password" style={inputStyle}
                           value={formData.password}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}/>
                    <IconButton
                        onClick={togglePasswordVisibility}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            padding: "5px"
                        }}
                        aria-label="toggle password visibility"
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                </div>
                {errors.password && <p style={{color: 'red', fontSize: "12px"}}>{errors.password}</p>}



            </DialogContent>

            <DialogActions>
                <SoftButton variant="outlined" color="dark" onClick={handleCancel}>
                    Cancel
                </SoftButton>
                <SoftButton variant="gradient" color="primary" onClick={handleSubmit} type="submit" >
                    {isActivating ? 'Please Wait...' : ' Send Email And Close'}
                    {isActivating && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                </SoftButton>
            </DialogActions>

            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

ResetPasswordDialog.propTypes = {

    open: PropTypes.bool,
    onClose: PropTypes.func,
    onLibrarianAdded: PropTypes.func,
    librarianData: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        password: PropTypes.string,
    }),
    isSuperAdmin: PropTypes.bool.isRequired,
}

export default ResetPasswordDialog;
