import { useState, useEffect, useMemo } from "react";

// react-router components
import {Routes, Route, Navigate, useLocation, BrowserRouter, useNavigate} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { jwtDecode } from "jwt-decode";

// Soft UI Dashboard React examples
import Sidenav from "reusableComponents/Sidenav";

// Soft UI Dashboard React themes
import theme from "assets/theme";

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import { useSoftUIController} from "context";

// Images
import brand from "assets/images/logo.png";
import brandIco from "assets/images/favicon.ico";
import sideBarItems from "./sideBarItems";
import {setEulaAccepted, setToken, setUserInfo} from "./store/actions/app-actions";
import EulaAgreementDialog from "./layouts/dashboard/EulaAgreementDialog";
import {acceptEula, logout} from "./axios-client";

export default function App() {
    const [controller, dispatch] = useSoftUIController();

    const { direction, layout, sidenavColor, role, token, miniSidenav, eulaAccepted, user_id} = controller;
    const { pathname } = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const isTokenExpired = (decodedToken) => {
        if (!decodedToken) return true;

        try {
            const currentTime = Date.now() / 1000;
            return decodedToken.exp < currentTime;

        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    };
    const getDecodedToken = (token) => {
        if (!token) return null;

        try {
            const decodedToken = jwtDecode(token);
            return decodedToken;

        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    useEffect(() => {

        const storageToken = localStorage.getItem('token');
        if (token == null && storageToken) {

            const decodedToken = getDecodedToken(storageToken);
  
            if (isTokenExpired(decodedToken)) {

                localStorage.removeItem('token');
                navigate("/authentication/sign-in");

            } else {
                setToken(dispatch, storageToken);
                if (decodedToken && decodedToken.hasOwnProperty('eula_accepted')) {
                    setEulaAccepted(dispatch, decodedToken.eula_accepted);
                }

                if (decodedToken && decodedToken.role && decodedToken.name) {
                    setUserInfo(dispatch, decodedToken)
                }
            }
        } else if (token) {
            const decodedToken = getDecodedToken(token);
            if (decodedToken && decodedToken.role && decodedToken.name) {
                setUserInfo(dispatch, decodedToken)
            }

            if (decodedToken && decodedToken.hasOwnProperty('eula_accepted')) {
                setEulaAccepted(dispatch, decodedToken.eula_accepted);
            }
        }
    }, [role, token]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });


    const handleDeclineEula = () => {
        logout().then(response => {
            localStorage.removeItem('token');
            setToken(dispatch, null);

        }).catch(error => {

            localStorage.removeItem('token');
            setToken(dispatch, null);
        });
    }
    const handleAcceptEula = () => {

        acceptEula()
            .then((response) => {

                setToken(dispatch, response.data.access_token);
                setEulaAccepted(dispatch, true);
                localStorage.setItem("token", response.data.access_token);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {layout === "dashboard" && role && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={!miniSidenav ? brand : brandIco}
                        brandName="YaiGlobal"
                        routes={sideBarItems[role]}
                    />
                </>
            )}
            <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>

            <EulaAgreementDialog
                open={token != null && !eulaAccepted}
                handleDecline={handleDeclineEula}
                handleAccept={handleAcceptEula}
            />
        </ThemeProvider>
    );
}
