import React from 'react';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import paypalCreditCard from "assets/images/paypal-credit-card.png";

const PaymentButton = ({handleClick}) => {
    return (
        <>
            <button style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "7px",
                margin: "7px 0",
                border: "1px solid #ccc",
                borderRadius: "5px",
                cursor: "pointer",
                width: "180px",
                background: "#0070ba"
            }} onClick={handleClick}>
                <span style={{fontSize: "13px", fontWeight: "bold", color: "white"}}>
                    Pay now
                </span>
            </button>

            <Button style={{padding: '0'}} onClick={handleClick}>
                <CardMedia
                    component="img"
                    sx={{width: 150, borderRadius: 0, margin: "0", height: '30px', marginLeft: '20px'}}
                    image={paypalCreditCard}
                    alt={'credit-card'}
                />
            </Button>
        </>
    );
};

PaymentButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
};

export default PaymentButton;
