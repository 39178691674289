import SoftButton from "../../components/SoftButton";
import PropTypes from "prop-types";

const Action = ({handleSubmitAction, actionName}) => {

    return (
        <SoftButton variant="outlined" color="secondary" size={"small"} onClick={handleSubmitAction}>{actionName}</SoftButton>
    );

}

Action.propTypes = {
    handleSubmitAction: PropTypes.func,
    actionName: PropTypes.string
}
export default Action;