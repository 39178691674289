import React from 'react';
import {MdCloudUpload} from "react-icons/md";
import PropTypes from "prop-types";

function FileUpload({setFormData, setErrors, handleFileUpload, formData, errors}) {
    return (
        <>
            <label htmlFor="fileUpload" className="label-style">Upload Document (PDF)</label>
            <div
                style={{
                    border: '2px dashed #cdcecf',
                    borderRadius: '8px',
                    padding: '20px',
                    textAlign: 'center',
                    position: 'relative',
                    marginBottom: '10px'
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    const droppedFile = e.dataTransfer.files[0];
                    if (droppedFile && droppedFile.type === 'application/pdf') {
                        setFormData(prevState => ({
                            ...prevState,
                            file: droppedFile
                        }));
                        setErrors(prevErrors => ({...prevErrors, file: ''}));
                    } else {
                        setErrors(prevErrors => ({...prevErrors, file: 'Only PDF files are allowed'}));
                    }
                }}
            >
                <input
                    type="file"
                    id="fileUpload"
                    accept="application/pdf"
                    style={{display: 'none'}}
                    onChange={handleFileUpload}
                />
                <label htmlFor="fileUpload" style={{cursor: 'pointer'}}>
                    <MdCloudUpload size={48}/>
                    <p>{formData.file ? formData.file.name : "Add or drag a file"}</p>
                </label>
            </div>
            {errors.file && <p className="error-text">{errors.file}</p>}        </>
    );
}

FileUpload.propTypes = {

    setFormData: PropTypes.func,
    setErrors: PropTypes.func,
    handleFileUpload: PropTypes.func,
    formData: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.arrayOf(PropTypes.string),
}

export default FileUpload;
