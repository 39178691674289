import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import signUpBgImage from "assets/images/curved-images/login.png"; 
import logo from "assets/images/logo.png"; 
import Alert from "@mui/material/Alert";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; 
import { UpdatePassword } from "../../../axios-client"; 
import CircularProgress from "@mui/material/CircularProgress";


function ResetNewPassword() {
  const { token } = useParams(); 
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  

    
  const handleResetPassword = async (event) => {
    event.preventDefault();

    setErrorMessage(null);
    setSuccessMessage(null);

    if (!newPassword || !confirmPassword) {
        setErrorMessage("Please fill in both password fields.");
        return;
    }

    if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords do not match.");
        return;
    }

    try {
        await UpdatePassword(token, newPassword, confirmPassword);
        setSuccessMessage("Your password has been reset successfully.");
        setIsLoading(true);
        setTimeout(() => navigate("/authentication/sign-in"), 3000);
    } catch (error) {
        if (error.response && error.response.data) {
            if (error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else if (error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred, please try again later!");
            }
        } else {
            setErrorMessage("An error occurred, please try again later!");
        }
        setIsLoading(false);
    }
};

  return (
    <CoverLayout
      title="Set New Password"
      description="Enter and confirm your new password to reset it"
      image={signUpBgImage} 
      logo={logo}
    >
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Alert>
      )}

      {successMessage && (
        <Alert severity="success" onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      )}

      <SoftBox component="form" role="form" onSubmit={handleResetPassword}>
      {isLoading && (
          <SoftBox display="flex" justifyContent="center" my={2}>
            <CircularProgress size={24} />
          </SoftBox>
        )}
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              New Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Confirm New Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="primary" fullWidth type="submit">
            Reset Password
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ResetNewPassword;
