import {getOrdersList} from "../../axios-client";
import React, { useState, useEffect } from 'react';
import Tables from "../../reusableComponents/Tables/Table/TableCard";
import {formatDataToUrlQueryParams, getStatusIcon} from "../../utlis/generalUtils";


const PaymentList = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ordersCount, setOrdersCount] = useState(0);

    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
    });

    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    useEffect(()=> {
        const queryParams = formatDataToUrlQueryParams(filterData);
        getOrdersList(`?${queryParams}`)
            .then(res => {

                const formattedRows = res.data.orders.map(order => ({
                    "Order ID": order.order_id,
                    "Date": order.date,
                    ...(order.payer_email ? { "Email": order.payer_email.toLowerCase() } : {}),
                    "Payment Method" : (
                <>
                    {getStatusIcon(order?.payment_method)}
                </>
            ),
                    "Document title":  order.articles.map(article => article.title).join(", "),
                    "Pages": order.articles.map(article => article.page_count).join(", "),
                    "Status": (
                        <>
                            {getStatusIcon(order?.status)} {order.status}
                        </>
                    ),
                    "Amount (USD)": `$${order.amount}`,
                }));

                setRows(formattedRows)
                setLoading(false);
                setOrdersCount(res.data.count)
        }).catch(error => {
            console.error('Error fetching orders:', error);
            setLoading(false);
        });

    }, [filterData])

    return (
        <>
            <Tables rows={rows} title={"Order List"}  loadingData={loading}
                    size={filterData.size}
                    page={filterData.page}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    count={ordersCount}
            />

        </>
    );
}
export default PaymentList;