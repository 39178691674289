import React from "react";
import PropTypes from "prop-types";
import { TableContainer, Table as MuiTable, TableHead, TableRow, TableBody } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import typography from "../../../assets/theme/base/typography";

const CollapsedTable = ({ collapsedRows, row, selector }) => {
    const { size, fontWeightBold } = typography;

    let keys = collapsedRows[row[selector]].length > 0 ? Object.keys(collapsedRows[row[selector]][0]) : [];

    let keysNames = keys.map((key) => {
        return {
            name: key
        }
    })


    const collapsedColumns = keysNames.map(({ name }, key) => {

            let pl;
            let pr;
            let align = "left"
            let width = "auto";

            if (key === 0) {
                pl = 3;
                pr = 3;
            } else if (key === keys.length - 1) {
                pl = 3;
                pr = 3;
            } else {
                pl = 1;
                pr = 1;
            }
              return ( <SoftBox
                    key={name}
                    component="th"
                    width={width || "auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={align === "left" ? pl : 3}
                    pr={align === "right" ? pr : 3}
                    textAlign={align}
                    fontSize={size.xs}
                    fontWeight={fontWeightBold}
                    color="secondary"
                    opacity={0.7}
                    borderBottom="1px solid #e0e0e0"
                >
                    {name}
                </SoftBox> );

        });




    const renderCollapsedRows = () => {
        return collapsedRows[row[selector]].map((collapsedRow, index) => (
            <TableRow key={index}>
                {Object.values(collapsedRow).map((value, keyIndex) => (
                    <SoftBox
                        key={keyIndex}
                        component="td"
                        pt={1.5}
                        pb={1.25}
                        pl={3}
                        pr={3}
                        textAlign="left"
                        borderBottom="1px solid #e0e0e0"
                    >
                        <SoftTypography variant="button" fontWeight="regular" color="secondary" sx={{ fontSize: '0.8rem' }}>
                            {value}
                        </SoftTypography>
                    </SoftBox>
                ))}
            </TableRow>
        ));
    };

    return (
        <TableContainer
            sx={{
                boxShadow: '0rem 0.25rem 1.6875rem 1rem rgba(0, 0, 0, 0.05)',
                borderRadius: '0.5rem',
                overflow: 'hidden',
            }}
        >
            <MuiTable>
                <SoftBox component="thead">
                    <TableRow>{collapsedColumns}</TableRow>
                </SoftBox>
                <TableBody>{renderCollapsedRows()}</TableBody>
            </MuiTable>
        </TableContainer>
    );
};

// PropTypes validation
CollapsedTable.propTypes = {
    collapsedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    row: PropTypes.object,
    selector: PropTypes.string
};

export default CollapsedTable;
