import React from 'react';
import SoftTypography from '../../components/SoftTypography';
import SuiButton from '../../components/SoftButton';
import { DialogActions, Dialog, DialogTitle, DialogContent, Divider } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import PropTypes from 'prop-types';
import { MdWarning } from 'react-icons/md';
import {deleteArticle} from "../../axios-client";

function DeleteArticleDialog({
                                 isModalOpen,
                                 handleCloseModal,
                                 articleId,
                                 articleTitle,
                                 refreshArticles,
                                 setNotification
                             }) {
    const handleDelete = async () => {
        if (articleId) {
            try {
                const response = await deleteArticle(articleId);
                if (response.status === 200) {
                    setNotification({
                        show: true,
                        snackbarMessage: 'Article deleted successfully!',
                        severity: 'success'
                    });
                    refreshArticles();
                    handleCloseModal();
                } else if (response.status === 403) {
                    setNotification({
                        show: true,
                        snackbarMessage: 'Unauthorized: You do not have permission to delete this article.',
                        severity: 'error'
                    });
                } else if (response.status === 404) {
                    setNotification({
                        show: true,
                        snackbarMessage: 'Article not found!',
                        severity: 'error'
                    });
                } else {
                    setNotification({
                        show: true,
                        snackbarMessage: 'Failed to delete the article!',
                        severity: 'error'
                    });
                }
            } catch (error) {
                setNotification({
                    show: true,
                    snackbarMessage: 'An error occurred. Please try again later.',
                    severity: 'error'
                });
            }
        }
    };
    return (
        <>
            <Dialog open={isModalOpen} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
                <DialogTitle id="form-dialog-title" sx={{ display: 'flex', alignItems: 'center' }}>
                    Your attention is required
                    <IoClose
                        aria-label="close"
                        onClick={handleCloseModal}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            cursor: 'pointer',
                        }}
                    />
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ padding: '10px', textAlign: 'center' }}>
                    <MdWarning size={80} style={{ color: '#D32F2F' }} />

                    <SoftTypography component="div" variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>

                        This article will be deleted !
                    </SoftTypography>

                    <SoftTypography component="div" variant="body2" color="secondary" style={{ fontSize: '16px' }}>
                        “{articleTitle}”
                    </SoftTypography>
                </DialogContent>
                <Divider />
                <DialogActions >
                    <SuiButton variant="gradient" color="secondary"  size="medium" onClick={handleCloseModal}>
                        Cancel
                    </SuiButton>
                    <SuiButton variant="contained" color="primary" size="medium" onClick={handleDelete}>
                        Delete
                    </SuiButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

DeleteArticleDialog.propTypes = {
    isModalOpen: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    handleDelete: PropTypes.func,
    articleId: PropTypes.string,
    articleTitle: PropTypes.string,
    refreshArticles: PropTypes.func,
    setNotification: PropTypes.func,
};

export default DeleteArticleDialog;
