import React, {useState} from "react";
import PropTypes, {object} from "prop-types";
import CardItem from "./CardItem";
import SoftBox from "../../../components/SoftBox";
import {downloadDocFile, downloadFileFromUrl} from "../../../utlis/generalUtils";
import Pagination from "@mui/material/Pagination";
import {MenuItem, Select} from "@mui/material";
import DownloadDigitizedArticleDialog from "../../../layouts/publications/DownloadDigitizedArticleDialog";
import PreviewCard from "../../../layouts/publications/components/PreviewCard";

const CardComponent = ({
                           data,
                           count,
                           size,
                           page,
                           handlePageChange,
                           handleRowsPerPageChange,
                           loadingData,
                           getArchivesBySelectedDocument,
                           setNotification,
                           searchText,
                           refreshArticles,
                           refreshList
                       }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const initialExpandedSections = {
        ocr_text: false,
        translated_text: false,
        summary: false,
        bullet_points_summary: false,
        description: false
    }
    const [expandedSections, setExpandedSections] = useState(initialExpandedSections);
    const initialSelectedFields = {
        ocr_text: false,
        translated_text: false,
        summary: false,
        bullet_points_summary: false,
        description: false
    }
    const [selectedFields, setSelectedFields] = useState(initialSelectedFields);
    const [docData, setDocData] = useState(null);

    const handlePaginationSizeChange = (event) => {
        let newSize = parseInt(event.target.value, 10);
        handleRowsPerPageChange(newSize);
    };

    const handlePaginationPageChange = (event, newPage) => {
        const numberOfPages = Math.ceil((count ?? data.length) / size);
        let page = newPage <= numberOfPages ? newPage : 1;
        handlePageChange(page);
    };

    const handleOpenModal = async (id) => {
        const selectedDocumentData = await getArchivesBySelectedDocument(id);
        if (selectedDocumentData) {
            setDocData(selectedDocumentData);
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setDocData(null);
    };

    const handleFieldChange = (field) => {
        setSelectedFields((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const handleClickDownloadDigitizedDocument = async (title, paragraphs) => {
        await downloadDocFile(title, paragraphs);
    }

    const handleDownload = async () => {
        await handleClickDownloadDigitizedDocument(docData?.title,
            [
                selectedFields.ocr_text ? `**Extracted Text:**\n${docData?.ocr_text ?? ""}` : "",
                selectedFields.translated_text ? `**Translated Text:**\n${docData?.translated_text ?? ""}` : "",
                selectedFields.summary ? `**Summary:**\n${docData?.summary ?? ""}` : "",
                selectedFields.bullet_points_summary ? `**Bullet Points Summary:**\n${docData?.bullet_points_summary ?? ""}` : "",
                selectedFields.description ? `**Description:**\n${docData?.description ?? ""}` : "",
            ])
        setSelectedFields(initialSelectedFields)
        handleCloseModal();
    };

    const handleOpenPreview = async (id) => {
        const selectedDocumentData = await getArchivesBySelectedDocument(id);
        if (selectedDocumentData) {
            setPreviewData(selectedDocumentData);
            setIsPreviewOpen(true);
        }
    };

    const handleClosePreview = () => {
        setIsPreviewOpen(false);
        setPreviewData(null);
        setExpandedSections(initialExpandedSections)
    };

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const downloadBookmarkingFile = async (id) => {
        const selectedDocumentData = await getArchivesBySelectedDocument(id);
        if (selectedDocumentData) {
            downloadFileFromUrl(selectedDocumentData.filename, selectedDocumentData.bookmarking_path)
        }
    };

    return (
        <React.Fragment>
            {data && data.map((element, index) => {
                return (
                    <SoftBox key={index} style={{paddingBottom: "5px"}}>
                        <CardItem
                            id={element.id}
                            title={element.title}
                            subjects={element.subjects}
                            author={element.author}
                            status={element.status}
                            paymentStatus={element.paymentStatus}
                            freeAgentDocuments={element.freeAgentDocuments}
                            image={element.picture_path}
                            publishedAt={element.publishedAt}
                            createdAt={element.createdAt}
                            summary={element.summary}
                            harmfulContent={element.harmfulContent}
                            searchText={searchText}
                            handleClickDownloadDigitizedDocument={() => handleOpenModal(element.id)}
                            handleOpenPreview={() => handleOpenPreview(element.id)}
                            downloadBookmarkingFile={() => downloadBookmarkingFile(element.id)}
                            getArchivesBySelectedDocument={getArchivesBySelectedDocument}
                            setNotification={setNotification}
                            refreshArticles={refreshArticles}
                            articleId={element.articleId}
                            cost={element.cost}
                            refreshList={refreshList}
                        />
                    </SoftBox>
                );
            })}
            {data.length > 0 ? (
                <SoftBox key={"pagination-card"}>
                    <SoftBox>
                        <SoftBox style={{display: "flex"}}>
                            <SoftBox style={{marginLeft: "auto"}}>
                                <Select
                                    id="rowsPerPage"
                                    fullWidth
                                    value={size}
                                    onChange={handlePaginationSizeChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px 0px",
                                        border: "none",
                                        fontSize: "14px",
                                        boxSizing: "border-box",
                                        outline: "none",
                                        fontFamily: "Arial, Roboto",
                                    }}
                                    inputProps={{"aria-label": "Without label"}}
                                >
                                    <MenuItem key={"5"} value="5">5</MenuItem>
                                    <MenuItem key={"10"} value="10" selected={true}>10</MenuItem>
                                    <MenuItem key={"20"} value="20">20</MenuItem>
                                </Select>
                            </SoftBox>
                            <SoftBox>
                                <Pagination
                                    size="sm"
                                    color="primary"
                                    count={Math.ceil(count / size)}
                                    page={page}
                                    onChange={handlePaginationPageChange}
                                    showFirstButton
                                    showLastButton
                                    style={{marginLeft: "auto"}}
                                />
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            ) : loadingData ? (
                <p style={{textAlign: "center", padding: "50px"}}> Loading... </p>
            ) : (
                <p style={{textAlign: "center", padding: "50px"}}> No results found!</p>
            )}

            <DownloadDigitizedArticleDialog
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                docData={docData}
                selectedFields={selectedFields}
                handleFieldChange={handleFieldChange}
                handleDownload={handleDownload}
            />


            <PreviewCard
                isPreviewOpen={isPreviewOpen}
                handleClosePreview={handleClosePreview}
                previewData={previewData}
                expandedSections={expandedSections}
                toggleSection={toggleSection}
            />

        </React.Fragment>
    );
};

CardComponent.propTypes = {
    data: PropTypes.arrayOf(object),
    count: PropTypes.number,
    size: PropTypes.number,
    page: PropTypes.number,
    loadingData: PropTypes.bool,
    searchText: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleSizeChange: PropTypes.func,
    handleClickOpen: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    getArchivesBySelectedDocument: PropTypes.func,
    setNotification: PropTypes.func,
    refreshArticles: PropTypes.func,
    refreshList: PropTypes.func,
};

export default CardComponent;
