import SoftBadge from "../../components/SoftBadge";
import PropTypes from "prop-types";

const Badge = ({color, badgeContent, variant}) => {

    return (
        <SoftBadge variant={variant} color={color} badgeContent={badgeContent} size="xs" container />
    );

}

Badge.propTypes = {
    variant: PropTypes.string,
    color: PropTypes.string,
    badgeContent: PropTypes.string
}
export default Badge;