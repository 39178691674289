import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Table from "../../../reusableComponents/Tables/Table";
import SoftButton from "../../../components/SoftButton";
import {exportDigitizationsReport} from "../../../axios-client";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { BiSolidFileExport } from "react-icons/bi";
import PropTypes from "prop-types";
import {formatFloat, formatTimeToMinutes} from "../../../utlis/generalUtils";

const PerformanceDataTableComponent = (props) => {
    const { rows, pageSize, page, handlePageChange, handleRowsPerPageChange, count, startDate, endDate } = props;


    const handleExport = () => {
        let queryParams = '';
        let filename = 'Digitizations_performance_Report';


        if (startDate && endDate) {
            queryParams = `start_date=${startDate}&end_date=${endDate}`;
            filename += `_${startDate}-${endDate}`;
        }

        exportDigitizationsReport(`?${queryParams}`)
            .then(res => {
                const exportData = res.data.map(digitization => ({
                    "User": digitization.user_name,
                    "Article title": digitization.digitization.title,
                    "Upload date": digitization.created_at,
                    "Size": digitization.size,
                    "Pages": digitization.page_count,
                    "Time (mn)": formatFloat(formatTimeToMinutes(digitization.digitization_time),3),
                    "CPU(m - cores)":`${formatFloat(digitization.cpu_usage * 1000, 2)} - ${formatFloat(digitization.cpu_usage, 4)} `,
                    "Memory (MB)": formatFloat(digitization.memory_usage,2)
                }));

                const worksheet = XLSX.utils.json_to_sheet(exportData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Digitizations Report");

                const exportFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });


                saveAs(new Blob([exportFile]), `${filename}.xlsx`);
            })
            .catch(error => {
                console.error('Error exporting digitizations report:', error);
            });
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', float:"right" }}>
                <SoftButton variant="outlined" color="secondary" size={"small"}
                            startIcon={<BiSolidFileExport />}
                            onClick={handleExport}
                >Export</SoftButton>

            </div>
            <Table
                rows={rows}
                pageSize={pageSize}
                page={page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                count={count}
            />
        </div>
    );
};

PerformanceDataTableComponent.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    pageSize: PropTypes.number,
    page: PropTypes.number,
    count: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};


export default PerformanceDataTableComponent;
