import {useState, useEffect} from "react";

// react-router components
import {useLocation, Link} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import Breadcrumbs from "reusableComponents/Breadcrumbs";
// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "reusableComponents/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
    useSoftUIController,
} from "context";

import {setMiniSidenav, setPicture, setProfileData, setToken, setTransparentNavbar} from "store/actions/app-actions";
import {getProfile, logout} from "../../../axios-client";
import UserAvatar from "./UserAvatar";

function DashboardNavbar({absolute = false, light = false, isMini = false}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, username, picture, role} = controller;
    const route = useLocation().pathname.split("/").slice(1);

    useEffect(() => {
        if (role) {
            getProfile(role).then(res => {
                setPicture(dispatch, res.data.profile_picture);
                setProfileData(dispatch, res.data);
            }).catch((error) => {

            });
        }

        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar, role]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    const handleLogout = () => {

        logout().then(response => {
            localStorage.removeItem('token');
            setToken(dispatch, null);

            console.log('you are disconnected !')
        }).catch(error => {

            localStorage.removeItem('token');
            setToken(dispatch, null);
        });

    }

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, {transparentNavbar, absolute, light})}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>
                </SoftBox>
                {isMini ? null : (
                    <SoftBox sx={(theme) => navbarRow(theme, {isMini})}>

                        <SoftBox color={light ? "white" : "inherit"}>
                            <IconButton sx={navbarIconButton} size="small">
                                <UserAvatar username={username} picture={picture} handleLogout={handleLogout}/>
                            </IconButton>
                            <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                        </SoftBox>

                    </SoftBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
