import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from "@mui/material/Divider";
import SoftButton from "../../components/SoftButton";
import PropTypes from "prop-types";

const EulaAgreementDialog = ({ open, handleAccept, handleDecline }) => {

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                End User License Agreement (EULA)
            </DialogTitle>
            <Divider />
            <DialogContent dividers={scroll === 'paper'} sx={{padding: "0px 24px", fontSize:"14px"}}>

                <b>Last Updated</b> : 2024
                <br />

                This End User License Agreement (`Agreement`) is a legal agreement between you (`User`) and <b>Yaiglobal</b>, with its principal place of business located at 5201 Great America Parkway, Suite 320, Santa Clara, CA 95054, United States, and phone number +1 408 335-5556, email contact@yaiglobal.com (the `Company`).

                By accessing or using the YAI4EDU web application <a href={"https://yai4edu.yai365.com/"}>Yai4Edu</a>  (the `Software`), you agree to be bound by the terms and conditions of this Agreement.

                <br />
                <b>License Grant: </b>
                The Company grants you a non-exclusive, non-transferable, revocable license to use the Software solely for your personal, non-commercial use. This license does not allow you to resell or redistribute the Software or any related services.

                <br />
                <b>User Responsibilities: </b> You are solely responsible for ensuring that your use of the Software complies with all applicable laws and regulations. The Software is designed to assist with digitizing documents, but the User assumes full responsibility for ensuring the correctness, legality, and integrity of any documents digitized through the Software.

                <br />
                <b>Disclaimer of Responsibility for Digitized Documents: </b> Yaiglobal <b>is not responsible</b> for the content, legality,  or any violations of law related to the documents digitized by the User while using the Software. The User is solely responsible for verifying the correctness, legality, and compliance of any digitized content with local, national, or international laws. Any legal consequences arising from the content of digitized documents are the sole responsibility of the User.

                <br />
                <b>Limitations of Use You agree not to:</b>

                Modify, copy, distribute, or create derivative works of the Software.
                <br />
                Use the Software for any illegal or unauthorized purpose, including but not limited to uploading, storing, or distributing documents that violate the law, infringe upon intellectual property rights, or contain defamatory, offensive, or harmful content.
                <br />
                Attempt to reverse-engineer, decompile, or disassemble the Software.

                <br />
                <b>Disclaimer of Warranties </b> The Software is provided `as is` without any express or implied warranties. The Company disclaims any warranties of merchantability, fitness for a particular purpose, or non-infringement.

                <br />
                <b>Limitation of Liability </b> In no event will the Company be liable for any damages arising from your use of the Software, including but not limited to direct, indirect, incidental, or consequential damages, even if the Company has been advised of the possibility of such damages. The User acknowledges that they are using the Software at their own risk and that all legal responsibility for the content of digitized documents lies with the User.

                <br />
                <b>Termination </b> This Agreement is effective until terminated. The Company may terminate this Agreement at any time without notice if you fail to comply with any of its terms. Upon termination, you must cease all use of the Software.

                <br />
                <b>Governing Law </b> This Agreement is governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles.

                <br />
                <b>Contact Information </b> If you have any questions about this Agreement, you can contact us at:

                <ul style={{marginLeft: "25px"}}>
                    <li>
                    <b>Address: </b> 5201 Great America Parkway, Suite 320, Santa Clara, CA 95054, United States
                    </li>
                    <li>
                    <b>Phone: </b> +1 408 335-5556
                    </li>
                    <li>
                    <b>Email: </b> contact@yaiglobal.com
                    </li>
                </ul>
                <br />
                By using the Software, you acknowledge that you have read and understood this Agreement and agree to be bound by its terms.

                You must accept the terms of the End User License Agreement (EULA) to use the platform. If you disagree with the terms, you cannot continue.
            </DialogContent>

            <DialogActions>
                <SoftButton variant="outlined" color="dark" onClick={handleDecline}>
                    Decline
                </SoftButton>
                <SoftButton variant="gradient" color="primary" onClick={handleAccept}>
                    Accept
                </SoftButton>
            </DialogActions>

        </Dialog>

    );
}

EulaAgreementDialog.propTypes = {
    open: PropTypes.bool,
    handleAccept: PropTypes.func,
    handleDecline: PropTypes.func
}
export default EulaAgreementDialog;