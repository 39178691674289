import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import signUpBgImage from "assets/images/curved-images/login.png"; 
import logo from "assets/images/logo.png"; 
import Alert from "@mui/material/Alert";
import { useState } from "react";
import { forgotPassword } from "../../../axios-client"; 
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";


function ResetPassword() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (event) => {
    event.preventDefault();

    setErrorMessage(null);
    setSuccessMessage(null);
    setIsLoading(true);

    if (!email) {
      setErrorMessage("Please enter your email address.");
      return;
    }

    try {
      await forgotPassword(email);
      setSuccessMessage("Password reset link has been sent to your email.");
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
        
      } else {
        setErrorMessage("An error occurred, please try again later!");
      }
      setIsLoading(false);
    }
  };

  return (
    <CoverLayout
      title="Reset Password"
      description="Enter your email to receive password reset instructions"
      image={signUpBgImage} 
      logo={logo} 
    >
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Alert>
      )}

      {successMessage && (
        <Alert severity="success" onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      )}
       <SoftBox component="form" role="form" onSubmit={handleForgotPassword}>
        {isLoading && (
          <SoftBox display="flex" justifyContent="center" my={2}>
            <CircularProgress size={24} />
          </SoftBox>
        )}
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </SoftBox>

        {/* Submit Button */}
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="primary" fullWidth type="submit">
            Send Reset Link
          </SoftButton>
          
        </SoftBox>

        {/* Link to go back to login page */}
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Remembered your password?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ResetPassword;
