// DeleteLibrarianDialog.jsx

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SoftBox from "../../components/SoftBox"; // Adjust the import path as necessary
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import { IoClose } from "react-icons/io5";
import { FaExclamationTriangle } from "react-icons/fa";
import PropTypes from "prop-types";

const DeleteLibrarianDialog = ({ open, onClose, onConfirm, librarian }) => {
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: '' });

    const handleConfirm = () => {
        if (librarian && librarian.id) {
            onConfirm(librarian.id);
        } else {
            setSnackbar({ open: true, message: 'Invalid librarian data.', severity: 'error' });
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}
                >
                    <SoftTypography variant="h6" fontWeight="bold">
                        Delete Librarian Admin
                    </SoftTypography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <IoClose />
                    </IconButton>
                </DialogTitle>
                <SoftBox component="hr" sx={{ border: 'none', borderBottom: '1px solid #dcdfe3' }} />
                <DialogContent dividers>
                    <SoftBox display="flex" alignItems="center" mb={2}>
                        <FaExclamationTriangle color="orange" size={24} style={{ marginRight: '16px' }} />
                        <SoftTypography variant="body1">
                            Are you sure you want to delete <strong>{librarian?.name}</strong>?
                        </SoftTypography>
                    </SoftBox>
                    <SoftTypography variant="body2" color="textSecondary">
                        This action cannot be undone. The librarian admin will no longer have access to the platform.
                    </SoftTypography>
                </DialogContent>
                <DialogActions sx={{ padding: '16px' }}>
                    <SoftButton variant="outlined" color="dark" onClick={onClose}>
                        Cancel
                    </SoftButton>
                    <SoftButton variant="gradient" color="primary" onClick={handleConfirm}>
                        Delete
                    </SoftButton>
                </DialogActions>
            </Dialog>

            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

DeleteLibrarianDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    librarian: PropTypes.shape({
        id: PropTypes.number.isRequired, 
        name: PropTypes.string.isRequired,
    }).isRequired,
};

export default DeleteLibrarianDialog;
