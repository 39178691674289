import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControlLabel, Checkbox, MenuItem, Select, Divider } from '@mui/material';
import SuiButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import { FaUniversity } from "react-icons/fa"; 
import { MdLocationOn, MdLocalPhone, MdEmail, MdWeb, MdCloudUpload } from "react-icons/md"; 
import { IoMdSchool } from "react-icons/io";
import PropTypes from 'prop-types';
import "./assets/universitiesStyleSheet.css";

const EditUniversityDialog = ({ open, onClose, handleUpdateUniversity, universityData }) => {
    const [formData, setFormData] = useState({
        name: '',
        country: '',
        address:'',
        email: '',
        website_url: '',
        logo_url: '',
        university_type: '',
        description: ''
    });
    const [errors, setErrors] = useState({});
    const [logoPreview, setLogoPreview] = useState('');
    
        useEffect(() => {
        if (open && universityData) {
            setFormData(universityData);
            setLogoPreview(universityData.logo_url || '');
        }
    }, [open, universityData]);

    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid #dcdfe3`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };

    const iconStyle = {
        marginRight: '8px',
        color: '#888'
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({ ...formData, logo_url: file });
            setLogoPreview(URL.createObjectURL(file));
        }
    };

    const handleCheckboxChange = (event) => {
        setFormData({ ...formData, is_active: event.target.checked });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'University name is required.';
        if (!formData.address) formErrors.address = 'Address is required.';
        if (!formData.email) formErrors.email = 'Email is required.';
        setErrors(formErrors);
        return formErrors;
    };

    const handleSubmit = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const formDataToSend = new FormData();
    
            Object.keys(formData).forEach((key) => {
                formDataToSend.append(key, formData[key]);
            });
    
            if (formData.logo_url) {
                formDataToSend.append('logo_url', formData.logo_url);
            }
                handleUpdateUniversity(universityData.id, formDataToSend);
            onClose();
        }
    };
    

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm"  PaperProps={{style: {borderRadius: 15, width: '100%'}}}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Edit University
                <IoClose aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, size: '1.5em' }} />
            </DialogTitle>
            <Divider/>
            <DialogContent>

                {/* Form fields */}
                <div style={inputContainerStyle}>
                    <FaUniversity style={iconStyle} />
                    <input  name="name" value={formData.name} onChange={handleChange} placeholder="University Name" style={inputStyle} />
                </div>

                <div style={inputContainerStyle}>
                    <MdEmail style={iconStyle} />
                    <input  name="email" value={formData.email} onChange={handleChange} placeholder="Email" style={inputStyle} />
                </div>

                <div style={inputContainerStyle}>
                    <MdLocalPhone style={iconStyle} />
                    <input  name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" style={inputStyle} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="upload-logo"
                    />
                    <label htmlFor="upload-logo" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        <MdCloudUpload size={48} />
                    </label>
                    {logoPreview && (
                        <img 
                            src={logoPreview}
                            style={{ 
                                height: '50px', 
                                width: '50px', 
                                borderRadius: '50%', 
                                marginLeft: '15px', 
                                marginTop: '12px' 
                            }} 
                            
                        />
                    )}
                </div>

                <div style={inputContainerStyle}>
                    <MdLocationOn style={iconStyle} />
                    <input  name="address" value={formData.address} onChange={handleChange} placeholder="State" style={inputStyle} />
                </div>

                <div style={inputContainerStyle}>
                    <MdWeb style={iconStyle} />
                    <input  name="website_url" value={formData.website_url} onChange={handleChange} placeholder="Website URL" style={inputStyle} />
                </div>

                <div style={inputContainerStyle}>
                    <IoMdSchool style={iconStyle} />
                    <Select
                        id="universityType"
                        displayEmpty
                        
                        value={formData.university_type}
                        onChange={(e) => setFormData({ ...formData, university_type: e.target.value })}
                        style={inputStyle}
                    >
                        <MenuItem value="" disabled>Select a university type</MenuItem>
                        <MenuItem value="Private">Private</MenuItem>
                        <MenuItem value="Public">Public</MenuItem>
                    </Select>
                </div>
                
                <TextField
                    multiline
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Description"
                    variant="outlined"
                    rows={4}
                    sx={{
                        width: '100%', 
                        '& .MuiInputBase-input': {
                            width: '100%',  
                            minWidth: '100%',  
                            maxWidth: '100%'  
                        }
                    }}
                />

            </DialogContent>
            <DialogActions>
                <SuiButton onClick={onClose} color="secondary">Cancel</SuiButton>
                <SuiButton onClick={handleSubmit} color="primary">Save</SuiButton>
            </DialogActions>
        </Dialog>
    );
};

EditUniversityDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleUpdateUniversity: PropTypes.func.isRequired,
    universityData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        website_url: PropTypes.string,
        logo_url: PropTypes.string,
        university_type: PropTypes.string,
        description: PropTypes.string
    })
};

export default EditUniversityDialog;
