import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider, Select, MenuItem, Alert } from '@mui/material';
import SuiButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import { LuBuilding } from "react-icons/lu";
import { LiaUniversitySolid } from "react-icons/lia";
import { MdLocalPhone } from "react-icons/md";
import { HiMiniUsers } from "react-icons/hi2";
import {getUniversities} from "../../axios-client";
import "./assets/departmentsStyleSheet.css";
import PropTypes from "prop-types";
import {useSoftUIController} from "../../context";
import {LIBRARY_ADMIN_ROLE} from "../../utlis/constant";


const AddDepartmentDialog = (props) => {
    const {open, onClose, handleAddDepartment} = props;
    const [isFocused, setIsFocused] = useState(false);
    const [controller] = useSoftUIController();
    const {role} = controller;

    console.log("props", props)

    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };


    const [universities, setUniversities] = useState([]);
    const [formData, setFormData] = useState(
        {
            department: '',
            university: '',
            phone: '',
            maxUsers: 1
        }
    )
    const [errors, setErrors] = useState({department: '', university: ''
    });

    useEffect(() => {
        getUniversities()
            .then(res => {
                setUniversities(res.data.universities);

                if (role === LIBRARY_ADMIN_ROLE) {
                    if (res.data.universities.length > 0 && !formData.university) {
                        setFormData(prevState => ({
                            ...prevState,
                            university: res.data.universities[0].id
                        }));
                    }
                }

            })
            .catch(error => {
                console.error('Error fetching universities:');
            });

    }, [role]);


    const handleInputChange = (e) => {
        const {name, value} = e.target;

        setErrors(prevErrors => ({...prevErrors, [name]: ''}));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

    };


    const handleSelectChange = (e) => {

        setErrors(prevErrors => ({...prevErrors, university: ''}));

        setFormData(prevState => ({
            ...prevState,
            university: e.target.value
        }));
    };


    const handleSave = () => {
        const newErrors = {};
        if (!formData.department) {
            newErrors.department = 'Department name is required.';
        }
        if (!formData.university) {
            newErrors.university = 'University is required.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        handleAddDepartment(formData);
    };

    return (
        <>
            <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth={"sm"} fullWidth PaperProps={{style: {borderRadius: 15}}}>
                <DialogTitle
                    id="form-dialog-title"
                    sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                >
                    Add Department
                    <IoClose
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            size: 10
                        }}
                    />
                </DialogTitle>
                <Divider/>
                <DialogContent sx={{padding: "0px 24px"}}>
                    <label htmlFor="department" className="label-style">Department name</label>
                    <div style={inputContainerStyle}>
                        <LuBuilding className="icon-style"/>
                        <input type="text" id="department" name="department" style={inputStyle}
                               value={formData.department}
                               onChange={handleInputChange}
                               onFocus={() => setIsFocused(true)}
                               onBlur={() => setIsFocused(false)}/>
                    </div>

                    {errors.department && <p style={{color: 'red', fontSize: "12px"}}>{errors.department}</p>}

                    <label htmlFor="university" className="label-style">University</label>

                    <div style={inputContainerStyle}>
                        <LiaUniversitySolid className="icon-style"/>
                        {role === LIBRARY_ADMIN_ROLE ? (
                                <input
                                    type="text"
                                    id="department"
                                    name="department"
                                    style={inputStyle}
                                    value={universities.find(university => university.id === formData.university)?.name || ''}
                                    disabled={true}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                />
                            ) :
                            <Select
                                id="university"
                                displayEmpty
                                fullWidth
                                defaultValue=""
                                value={formData.university}
                                onChange={handleSelectChange}
                                style={inputStyle}
                                inputProps={{'aria-label': 'Without label'}}
                            >
                                <MenuItem value="" disabled>Select a university</MenuItem>
                                {universities.map((university) => (
                                    <MenuItem key={university.id} value={university.id}>
                                        {university.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    </div>

                    <label htmlFor="phone" className="label-style">Phone</label>
                    <div style={inputContainerStyle}>
                        <MdLocalPhone className="icon-style"/>
                        <input type="tel" id="phone" name="phone" style={inputStyle}
                               value={formData.phone}
                               onChange={handleInputChange}
                               onFocus={() => setIsFocused(true)}
                               onBlur={() => setIsFocused(false)}/>
                    </div>

                    <label htmlFor="max-users" className="label-style">Max users</label>
                    <div style={inputContainerStyle}>
                        <HiMiniUsers className="icon-style"/>
                        <input type="number" id="maxUsers" name="maxUsers" style={inputStyle} min={0}
                               value={formData.maxUsers}
                               onChange={handleInputChange} onFocus={() => setIsFocused(true)}
                               onBlur={() => setIsFocused(false)}/>
                    </div>

                </DialogContent>
                <Divider/>
                <DialogActions>
                    <SuiButton variant="gradient" color="secondary" size="medium" onClick={onClose}>Cancel</SuiButton>
                    <SuiButton variant="gradient" color="primary" size="medium" onClick={handleSave}>
                        Save
                    </SuiButton>
                </DialogActions>

            </Dialog>
        </>
    );

};

AddDepartmentDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleAddDepartment: PropTypes.func
}
export default AddDepartmentDialog;
