import React, { useState, useEffect } from 'react';
import {getDepartementsList, getResearchersActivity, getResearchersList, getAnonymousUsersList, getUniversities} from "../../axios-client";
import Tables from "../../reusableComponents/Tables/Table/TableCard";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
import {formatDataToUrlQueryParams, getFormattedTodayDate} from "../../utlis/generalUtils";
import {useSoftUIController} from "../../context";
import {LIBRARIAN_ROLE, SUPER_ADMIN_ROLE, LIBRARY_ADMIN_ROLE} from "../../utlis/constant";
import { IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import DeleteArticleDialog from "../../layouts/publications/DeleteArticleDialog";
import { format } from 'date-fns';

const Report = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})
    const [articles, setArticles] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const today = getFormattedTodayDate();
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
        university_id: "",
        department_id:"",
        researcher_id:"",
        from: today,
        to: today
        })
    const [activitiesCount, setActivitiesCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [universities, setUniversities] = useState([{ id: "", name: "Select University" }]);
    const [departments, setDepartments] = useState([{ id: "", name: "Select Department" }]);
    const [researchers, setResearchers] = useState([{ id: "", name: "Select User" }]);
    const [controller] = useSoftUIController();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedArticleId, setSelectedArticleId] = useState(null);
    const [selectedArticleTitle, setSelectedArticleTitle] = useState("");
    const handleOpenDeleteDialog = (articleId, articleTitle) => {
        setSelectedArticleId(articleId);
        setSelectedArticleTitle(articleTitle);
        setOpenDeleteDialog(true);
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const {role, profileData} = controller;

    useEffect(() => {
        fetchData(filterData);
    }, [filterData]);

    useEffect(() => {

        if (profileData) {

            if (profileData.university) {

                setFilterData({
                    ...filterData,
                    university_id: profileData.university.id,
                })
            }

            if (profileData.departments) {

                setFilterData({
                    ...filterData,
                    department_id: profileData.departments[0].id,
                })
            }
        }

    }, [profileData]);

    useEffect(() => {
        let filterUsers = {};

        filterUsers.university_id = filterData.university_id;

        if (filterData.department_id !== "") {
            filterUsers.department_id = filterData.department_id;
        }
        let queryParams = formatDataToUrlQueryParams(filterUsers);

        if (filterData.department_id != "") {
            getAnonymousUsersList(`?${queryParams}`)
                .then(res => {
                    const defaultOption = [{id: "", name: "Select User"}];
                    setResearchers([...defaultOption, ...res.data.users]);
                })
                .catch(error => {
                    console.error('Error fetching researchers', error);
                });
        } else {
            setFilterData({
                ...filterData,
                researcher_id: ""
            });

            setResearchers([{ id: "", name: "Select User" }]);
        }
    }, [filterData.department_id]);
    
    useEffect(() => {

        if (filterData.university_id !== "") {

            let filterUniversity = {university_id: filterData.university_id}
            const queryParams = formatDataToUrlQueryParams(filterUniversity);

            getDepartementsList(`?${queryParams}`)
                .then(res => {
                    const defaultOption = [{id: "", name: "Select Department"}];
                    setDepartments([...defaultOption, ...res.data.departments]);
                })
                .catch(error => {
                    console.error('Error fetching departments:', error);
                });
        } else {
            setFilterData({
                ...filterData,
                department_id: "",
                researcher_id: ""
            });

            setDepartments([{ id: "", name: "Select Department" }]);
            setResearchers([{ id: "", name: "Select User" }]);
        }
    }, [filterData.university_id]);
    
    useEffect(() => {
        if (role && role === SUPER_ADMIN_ROLE) {
            getUniversities()
                .then(res => {
                    const defaultOption = [{ id: "", name: "Select University" }];
                    setUniversities([...defaultOption, ...res.data.universities]);
                })
                .catch(error => {
                    console.error('Error fetching universities:', error);
                });
        } else {

            // set library admin university
        }
    }, [role]);
    const handlePageChange = (newPage) => {

        setFilterData({...filterData, page: newPage});
    }
    const handleRowsPerPageChange = (newSize) => {

        setFilterData({...filterData, size: newSize, page: 1});
    }
    const handleFilterChange = (key, value) => {
        if (key === 'from' || key === 'to') {
            setFilterData({ ...filterData, from: value.from, to: value.to });
        } else if (key === 'University') {
            setFilterData({ 
                ...filterData, 
                university_id: value,
                department_id: "",
                Researcher: "" ,
                page: 1
            }); 
        } else if (key === 'Department') {
            setFilterData({ 
                ...filterData, 
                department_id: value,
                Researcher: "" ,
                page: 1
            }); 
        } else {
            setFilterData({ ...filterData, [key]: value });
        }
    };
    
    
    const refreshArticles = () => {
        fetchData(filterData);
    };
    const fetchData = (params) => {
        const queryParams = {
            page: params.page || 1,
            size: params.size || 10,
            department_id: params.department_id ?? null,
            researcher_id: params.researcher_id ?? null,
            from: params.from || null,
            to: params.to || null,
            university_id: params.university_id ?? null
        };

        getResearchersActivity(queryParams)
            .then(res => {
                const collapsedRows = [];
                const formattedRows = res.data.report.map(activity => {
                    return {
                        "#": activity.researcher_id,
                        "Researcher Name": (<AvatarComponent name={activity.researcher} image={activity.researcher_picture ?? activity.researcher}  bgColor={"secondary"} variant={"rounded"}/>),
                        "Department": activity.departments.map((department=> department.name)).join(", "),
                        "Total Articles": activity.number_of_articles,
                        "Total pages": activity.total_pages,
                    };

                });


                setRows(formattedRows);
                setActivitiesCount(res.data.total_researchers);
                setTotalPagesCount(res.data.total_pages)

                res.data.report.forEach(activity => {
                    collapsedRows[activity.researcher_id] = activity.articles.map(article => {
                        const articleData = {
                            "Title": article.article_title,
                            "Total pages": article.page_count,
                            "Digitized date": format(new Date(article.created_at), 'MMM dd, yyyy hh:mm a')
                        };

                        if (role === 'super-admin') {
                            articleData["Duration (hh:mm:ss)"] = article.duration || "Not available";
                            articleData["Action"] = (
                                <IconButton
                                    style={{ cursor: 'pointer' }}
                                    size="small"
                                    onClick={() => handleOpenDeleteDialog(article.article_id, article.article_title)}
                                >
                                    <Delete />
                                </IconButton>
                            );
                        }

                        return articleData;
                    });
                });


                setArticles(collapsedRows);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching activities:', error);
                setLoading(false);
            });
    }

    let filters = [];

    if (role === SUPER_ADMIN_ROLE) {
        filters = [
            {
                key: 'university_id',
                label: 'University',
                type: 'select',
                options: universities.map(univ => ({ id: univ.id, label: univ.name }))
            },
            {
                key: 'department_id',
                label: 'Department',
                type: 'select',
                options: departments.map(dep => ({ id: dep.id, label: dep.name }))
            },
            {
                key: 'researcher_id',
                label: 'User',
                type: 'select',
                options: researchers.map(res => ({ id: res.id, label: res.name }))
            },
            { 
                key: 'from', 
                label: 'Digitized date', 
                type: 'date' 
            }
        ];
    } else if (role === LIBRARIAN_ROLE) {
        filters = [
            {
                key: 'researcher_id',
                label: 'User',
                type: 'select',
                options: researchers.map(res => ({ id: res.id, label: res.name }))
            },
            { 
                key: 'from', 
                label: 'Digitized date', 
                type: 'date' 
            }
        ];
    } else if (role == LIBRARY_ADMIN_ROLE) {
        filters = [
            {
                key: 'department_id',
                label: 'Department',
                type: 'select',
                options: departments.map(dep => ({ id: dep.id, label: dep.name }))
            },
            {
                key: 'researcher_id',
                label: 'User',
                type: 'select',
                options: researchers.map(res => ({ id: res.id, label: res.name }))
            },
            {
                key: 'from',
                label: 'Digitized date',
                type: 'date'
            }
        ];
    }


    return (
        <>
            <Tables rows={rows} title={"Report"} loadingData={loading} collapsed={collapsed} collapsedRows={articles}
                    selector={"#"}
                    count={activitiesCount}
                    displayFooter={true}
                    footerRow={{key: "Total Pages", value: totalPagesCount}}
                    size={filterData.size}
                    page={filterData.page}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    filters={filters}
                    filterData={filterData}
                    handleFilterChange={handleFilterChange}
            />
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
            {openDeleteDialog && (
                <DeleteArticleDialog isModalOpen={openDeleteDialog} handleCloseModal={handleCloseDeleteDialog}  articleId={selectedArticleId}
                                     articleTitle={selectedArticleTitle}
                                     refreshArticles={refreshArticles} setNotification={setNotification}/>
            )}
        </>
    );
}
export default Report;