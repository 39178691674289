import React from 'react';
import {MdOutlineBook, MdPerson, MdDateRange} from "react-icons/md";
import {Select, MenuItem} from '@mui/material';
import PropTypes from "prop-types";

function MetaData({
                      inputContainerStyle,
                      inputStyle,
                      formData,
                      handleInputChange,
                      setIsFocused,
                      errors,
                      handleSelectChange,
                      languages,
                      handleBlur
                  }) {

    const handleTitleAndAuthorBlur = () => {
        handleBlur();
        setIsFocused(false)
    }

    return (
        <>
            <label htmlFor="title" className="label-style">Title</label>
            <div style={inputContainerStyle}>
                <MdOutlineBook className="icon-style"/>
                <input
                    type="text"
                    id="title"
                    name="title"
                    style={inputStyle}
                    value={formData.title}
                    onChange={handleInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleTitleAndAuthorBlur}
                    placeholder="Enter article title"
                />
            </div>
            {errors.title && <p className="error-text">{errors.title}</p>}

            <label htmlFor="author" className="label-style">Author</label>
            <div style={inputContainerStyle}>
                <MdPerson className="icon-style"/>
                <input
                    type="text"
                    id="author"
                    name="author"
                    style={inputStyle}
                    value={formData.author}
                    onChange={handleInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleTitleAndAuthorBlur}
                    placeholder="Enter author's name"
                />
            </div>
            {errors.author && <p className="error-text">{errors.author}</p>}

            <label htmlFor="publicationDate" className="label-style">Publication Date</label>
            <div style={inputContainerStyle}>
                <MdDateRange className="icon-style" />
                <input
                    type="text"
                    id="publicationDate"
                    name="publicationDate"
                    style={inputStyle}
                    value={formData.publicationDate}
                    onChange={handleInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder="Enter publication date"
                />
            </div>
            {errors.publicationDate && <p className="error-text">{errors.publicationDate}</p>}

            <div style={{display: 'flex', gap: '20px'}}>
                <div style={{flex: 1}}>
                    <label htmlFor="sourceLanguage" className="label-style">Source Language</label>
                    <Select
                        id="sourceLanguage"
                        fullWidth
                        value={formData.sourceLanguage}
                        onChange={handleSelectChange}
                        name="sourceLanguage"
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Select a source language</MenuItem>
                        {languages.map((languageData) => (
                            <MenuItem key={languageData.id} value={languageData.language}>
                                {languageData.language}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.sourceLanguage && <p className="error-text">{errors.sourceLanguage}</p>}
                </div>
                <div style={{flex: 1}}>
                    <label htmlFor="targetLanguage" className="label-style">Target Language</label>
                    <Select
                        id="targetLanguage"
                        fullWidth
                        value={formData.targetLanguage}
                        onChange={handleSelectChange}
                        name="targetLanguage"
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Select a target language</MenuItem>
                        {languages.map((languageData) => (
                            <MenuItem key={languageData.id} value={languageData.language}>
                                {languageData.language}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.targetLanguage && <p className="error-text">{errors.targetLanguage}</p>}
                </div>
            </div>
        </>
    );
}

MetaData.propTypes = {

    inputContainerStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    formData: PropTypes.object,
    handleInputChange: PropTypes.func,
    setIsFocused: PropTypes.func,
    errors: PropTypes.object,
    handleSelectChange: PropTypes.func,
    languages: PropTypes.arrayOf(PropTypes.object),
    handleBlur: PropTypes.func,
}
export default MetaData;
