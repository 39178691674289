import "../../editorStyleSheet.css"
import Scale from "./editorToolbarElements/Scale";
import PageNavigation from "./editorToolbarElements/PageNavigation";
import PropTypes from "prop-types";
import { FaExpand, FaCompress } from 'react-icons/fa';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";


const EditorToolbarComponent = ({handleFullScreen, isFullScreen, scale, setPageNumber, setNumPages, setScale, numPages, pageNumber, setForcePageNumber, setCloseForcePageNumber}) => {

    return (
        <div className="digitizationEditorToolbar">

            <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
                <IconButton aria-label={isFullScreen ? "Exit Full Screen" : "Full Screen"} id="basic-button"
                            style={{width: "5vh",
                                height: "5vh",
                                borderRadius: "15%",
                            }}
                            onClick={handleFullScreen}>
                    {isFullScreen ? <FaCompress style={{ fontSize: 'smaller' }} /> : <FaExpand style={{ fontSize: 'smaller' }} />}
                </IconButton>
            </Tooltip>

            <Scale
                scale={scale}
                setScale={setScale}
            />

            <PageNavigation
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                numPages={numPages}
                setNumPages={setNumPages}
                setForcePageNumber={setForcePageNumber}
                setCloseForcePageNumber={setCloseForcePageNumber}
            />


        </div>
    );

}

EditorToolbarComponent.propTypes = {
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    scale: PropTypes.number,
    setPageNumber: PropTypes.func,
    setNumPages: PropTypes.func,
    setScale: PropTypes.func,
    setForcePageNumber: PropTypes.func,
    setCloseForcePageNumber: PropTypes.func,
    handleFullScreen: PropTypes.func,
    isFullScreen: PropTypes.bool
};
export default EditorToolbarComponent;