import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import {useSoftUIController} from "../../context";

function isAuthenticated() {
    const token = localStorage.getItem('token');
    return token != null;
}

const PrivateRoute = ({ allowedRoles, component: Component, publicRoute }) => {

    const [controller, dispatch] = useSoftUIController();
    const { token, role } = controller;

    const isLoggedIn = isAuthenticated();

    if (!publicRoute) {

        if (!isLoggedIn) {
            return <Navigate to="/authentication/sign-in" />;
        }

        if (allowedRoles && role && !allowedRoles.includes(role)) {
            return <Navigate to="/dashboard" />;
        }
    }

    return <Component />;
};

PrivateRoute.propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    component: PropTypes.elementType.isRequired,
    publicRoute: PropTypes.bool,
};

export default PrivateRoute;
