/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import React, {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CollapsedTable from "./CollapsedTable";
import { MenuItem, Select } from "@mui/material";
import Pagination from "@mui/material/Pagination";

function Table({ rows = [] , collapsed, collapsedRows, displayFooter, footerRow, selector, handleRowsPerPageChange, handlePageChange, page, pageSize, count}) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  let keys = rows.length > 0 ? Object.keys(rows[0]) : [];

  let keysNames = keys.map((key) => {
    return {
    name: key
    }
  })


  const handlePaginationSizeChange = (event) => {
    let newSize = parseInt(event.target.value, 10);
    handleRowsPerPageChange(newSize);
  };

  const handlePaginationPageChange = (event, newPage) => {
    const numberOfPages = Math.ceil((count ?? rows.length) / pageSize);
    let page = newPage <= numberOfPages ? newPage : 1;
    handlePageChange(page);
  };

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (key) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [key]: !prevExpandedRows[key],
    }));
  };

  const renderColumns = keysNames.map(({ name }, key) => {
    let pl;
    let pr;
    let align = "left"
    let width = "auto";

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === keys.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 3;
      pr = 3;
    }



    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {name}
      </SoftBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;
    const isExpanded = expandedRows[rowKey];

    let pl;
    let pr;
    let align = "left"
    let width = "auto";

    if (key === keys.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 3;
      pr = 3;
    }

    const tableRow = keysNames.map(({ name}) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
            <SoftBox
                key={uuidv4()}
                component="td"
                p={1}
                borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
                <SoftBox mr={2}>
                  <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
                </SoftBox>
                <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                  {row[name][1]}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
        );
      } else {
        template = (
            <SoftBox
                key={uuidv4()}
                component="td"
                pt={1.5}
                pb={1.25}
                pl={align === "left" ? pl : 3}
                pr={align === "right" ? pr : 3}
                textAlign={align}
                borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                  sx={{ display: "inline-block", width: "max-content" }}
              >
                {row[name]}
              </SoftTypography>
            </SoftBox>
        );
      }

      return template;
    });

    const expandedContent = isExpanded && collapsed ? (
        <TableRow key={`${rowKey}-expanded`}>
          <SoftBox
              component="td"
              colSpan={keys.length}
              p={2}
              bgcolor={light.main}
          >
            <SoftTypography variant="body2">
              <CollapsedTable collapsedRows={collapsedRows} row={row} selector={selector}/>
            </SoftTypography>
          </SoftBox>
        </TableRow>
    ) : null;

    return (
        <React.Fragment key={rowKey}>
          <TableRow onClick={() => toggleRow(rowKey)} style={{ cursor: "pointer" }}>
            {tableRow}
          </TableRow>
          {expandedContent}
        </React.Fragment>
    );
  });




  return useMemo(
    () => (
      <TableContainer>
        <MuiTable sx={{ captionSide: 'left' }}>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
          {displayFooter &&
          <SoftBox component="tfoot">
            <TableRow >
              <React.Fragment>
              <SoftBox
                  key={uuidv4()}
                  component="td"
                  pt={1.5}
                  pb={1.25}
                  pl={3}
                  pr={3}
                  fontSize={size.xs}
                  opacity={0.7}
                  textAlign={"right"}
                  colSpan={keys.length - 1}
                  fontWeight="medium"
              >
                <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    sx={{ display: "inline-block", width: "max-content" }}
                >
                  {footerRow.key}
                </SoftTypography>
              </SoftBox>

              <SoftBox
                  key={uuidv4()}
                  component="td"
                  pt={1.5}
                  pb={1.25}
                  pl={3}
                  pr={3}
                  textAlign={"left"}
              >
                <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="secondary"
                    sx={{ display: "inline-block", width: "max-content" }}
                >
                  {footerRow.value}
                </SoftTypography>
              </SoftBox>
              </React.Fragment>
            </TableRow>
          </SoftBox>
          }
        </MuiTable>
        <SoftBox key={"pagination-card"}>
          <SoftBox>
            <SoftBox style={{display: "flex"}}>
              <SoftBox style={{marginLeft: "auto"}}>
                <Select
                    id="rowsPerPage"
                    fullWidth
                    value={pageSize}
                    onChange={handlePaginationSizeChange}
                    style={{
                      width: "100%",
                      padding: "10px 0px",
                      border: "none",
                      fontSize: "14px",
                      boxSizing: "border-box",
                      outline: "none",
                      fontFamily: "Arial, Roboto",
                    }}
                    inputProps={{"aria-label": "Without label"}}
                >
                  <MenuItem key={"5"} value="5">5</MenuItem>
                  <MenuItem key={"10"} value="10" selected={true}>10</MenuItem>
                  <MenuItem key={"20"} value="20">20</MenuItem>
                </Select>
              </SoftBox>
              <SoftBox>
                <Pagination
                    size="sm"
                    color="primary"
                    count={Math.ceil(count / pageSize)}
                    page={page}
                    onChange={handlePaginationPageChange}
                    showFirstButton
                    showLastButton
                    style={{marginLeft: "auto"}}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </TableContainer>


    ), [rows, expandedRows]
  );
}

// Typechecking props for the Table
Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  collapsedRows: PropTypes.arrayOf(PropTypes.object),
  collapsed: PropTypes.bool,
  displayFooter: PropTypes.bool,
  footerRow: PropTypes.object,
};

export default Table;
