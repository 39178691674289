import React from "react";
import {
    Collapse,
    Drawer,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "../../../components/SoftBox";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import PropTypes from "prop-types";
import defaultMiniature from "../../../assets/images/curved-images/digitization.jpg";
import CardMedia from "@mui/material/CardMedia";
import { format } from 'date-fns';

const PreviewCard = ({isPreviewOpen, handleClosePreview, previewData, expandedSections, toggleSection}) => {

    const imageSrc = previewData?.picture_path || defaultMiniature;
    const handleMoreInfoClick = (url) => {
        window.open(url, "_blank");
    };
    const renderCollapseSection = (label, contentKey) => (
        <>
            <ListItemButton onClick={() => toggleSection(contentKey)}>
                <ListItemIcon>
                    {expandedSections[contentKey] ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                </ListItemIcon>
                <ListItemText primary={<span style={{fontSize: '1rem'}}>{label}</span>}/>
            </ListItemButton>
            <Collapse in={expandedSections[contentKey]} timeout="auto" unmountOnExit
                      style={{
                          background: '#f2f3f3',
                          padding: "20px",
                          borderRadius: "10px"
                      }}
            >
                {typeof previewData?.[contentKey] === 'object' && previewData?.[contentKey] !== null ? (
                    <div style={{ fontSize: '0.9rem' }}>
                        {Object.entries(previewData?.[contentKey]).map(([key, value]) => (
                            <p key={key}>
                                <strong>{key.replace(/_/g, ' ')}:</strong> {value}
                            </p>
                        ))}
                    </div>
                ) : (
                    <p style={{
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        fontSize: '0.9rem'
                    }}>
                        {previewData?.[contentKey]}
                    </p>
                )}
            </Collapse>
        </>
    );

    return (
        <Drawer
            anchor="right"
            open={isPreviewOpen}
            onClose={handleClosePreview}
            PaperProps={{
                sx: {
                    width: '60%',
                    background: '#f8f9fa',
                    height: '100vh',
                    borderRadius: '0.2rem',
                    margin: 0
                },
            }}
        >
            <div style={{padding: "30px"}}>
                <IconButton onClick={handleClosePreview} style={{position: "absolute", top: 10, right: 10}}>
                    <CloseIcon/>
                </IconButton>
                <SoftBox display="flex" mt={2}>

                    <CardMedia
                        component="img"
                        sx={{width: 150, borderRadius: 0, margin: "0 30px 0 0"}}
                        image={imageSrc}
                        alt={previewData?.title}
                    />

                    <SoftBox>
                        <h4 style={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            paddingBottom: "10px"
                        }}>
                            {previewData?.title}
                        </h4>
                        <p style={{fontSize: '1rem'}}><strong>Author: </strong>{previewData?.author}</p>
                        <p style={{fontSize: '1rem'}}><strong>Published: </strong>{previewData?.publishedAt}</p>
                    </SoftBox>
                </SoftBox>

                <div style={{paddingTop: "40px"}}>

                    {renderCollapseSection("Extracted Text", "ocr_text")}
                    {renderCollapseSection("Translated Text", "translated_text")}
                    {renderCollapseSection("Summary", "summary")}
                    {renderCollapseSection("Bullet Points Summary", "bullet_points_summary")}
                    {renderCollapseSection("Description", "description")}
                    {renderCollapseSection("Document details", "details")}
                </div>

                <ListItemText primary={<span style={{
                    fontSize: '1rem', cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                    padding: "30px 0",
                    display: "inline-block",
                    borderRadius: "4px"
                }}
                   onClick={() => handleMoreInfoClick(previewData?.bookmarking_path)}
                >
                    Bookmarks
                </span>}/>


            </div>
        </Drawer>
    );
};

PreviewCard.propTypes = {
    isPreviewOpen: PropTypes.bool.isRequired,
    handleClosePreview: PropTypes.func.isRequired,
    previewData: PropTypes.object,
    expandedSections: PropTypes.object.isRequired,
    toggleSection: PropTypes.func.isRequired,
};

export default PreviewCard;
