import "../../editorStyleSheet.css"
import Switch from 'react-switch';
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from 'react';
import {getScriptDirection} from "../../../../utlis/generalUtils";


const DigitizationContentComponent = ({containerWidth, configWidth, pageNumber, updateConfigWidth, pages, sourceLanguage, targetLanguage}) => {

    const [displayExtractedText, setDisplayExtractedText] = useState(true);
    const [textContent, setTextContent] = useState('');

    const checkPageType = (currentPage) => {
        switch (currentPage.type) {
            case "NO_TEXT":
                setTextContent("No TEXT.");
                break;
            case "HARMFUL_CONTENT":
                setTextContent(displayExtractedText ? currentPage.content ?? "No OCR is available." : "NO-TEXT due to harmful content.");
                break;
            case "TEXT":
                setTextContent(displayExtractedText ? currentPage.content ?? "No OCR is available." : currentPage.translated_text ?? "No Translation is available.");

            default:
                setTextContent(displayExtractedText ? currentPage.content ?? "No OCR is available." : currentPage.translated_text ?? "No Translation is available.");
        }
    }


    useEffect(() => {
        const currentPage = pages[pageNumber - 1];
        if (currentPage) {
            checkPageType(currentPage)
        }
    }, [pageNumber, displayExtractedText, pages]);

    const handleSwitchChange = () => {
        setDisplayExtractedText(!displayExtractedText);
    };

    const updateText = (e) => {

        setTextContent(e.target.value);
    }


    const handleContentSectionMouseDown = useCallback((e) => {
        e.preventDefault();
        const initialWidth = e.target.parentElement.offsetWidth;
        const initialDivWidth = e.target.offsetParent.offsetWidth;
        const initialX = e.clientX;

        const handleMouseContentSectionMove = (e) => {
            let newWidth = initialWidth - (e.clientX - initialX);

            if (newWidth < initialDivWidth * 0.2) {
                newWidth = (initialDivWidth * 0.2);
            } else if (newWidth > initialDivWidth * 0.5) {
                newWidth = (initialDivWidth * 0.5);
            }

            if (document.getElementById("digitizationEditorContentSection") &&
                document.getElementById("digitizationEditorContentSection").offsetWidth != newWidth
            ) {
                document.getElementById("digitizationEditorContentSection").style.width = newWidth + 'px';
                updateConfigWidth(newWidth);
            }
            const event = new CustomEvent('configResized');
            window.dispatchEvent(event);

        };

        const handleMouseContentSectionUp = () => {
            document.removeEventListener('mousemove', handleMouseContentSectionMove);
            document.removeEventListener('mouseup', handleMouseContentSectionUp);
        };

        document.addEventListener('mousemove', handleMouseContentSectionMove);
        document.addEventListener('mouseup', handleMouseContentSectionUp);
    }, []);

    return (
        <div className="digitizationEditorContentSection" id={"digitizationEditorContentSection"}>
            <div className="resize-handle" onMouseDown={handleContentSectionMouseDown} />
            <div style={{borderBottom: '1px solid #e0d8d8b0', padding: "10px", height: "7%", display:"flex",
                flexDirection:"row", justifyContent:"center", gap:"1rem", minWidth: "max-content"}}>
                <div style={
                    {fontSize: "smaller"}}>
                    Translated Text
                </div>
                <div >
                <Switch
                    checked={displayExtractedText}
                    inputProps={{ 'aria-label': 'Toggle between original content and translated text' }}
                    onColor="#f00084"
                    offColor="#888"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={10}
                    height={15}
                    width={30}
                    onChange={handleSwitchChange}
                />
                </div>
                <div style={
                    {fontSize: "smaller"}}>
                    Extracted Text
                </div>

            </div>

            <div style={{
                fontWeight: 400,
                paddingTop: "20px",
                display:"flex",
                justifyContent:"center",
                fontSize: 'smaller',
            }}>
                <span>page - {pageNumber}</span>
            </div>

                <div style={{padding: "0px 15px 0px 15px", width:"100%"}}>

                        <textarea
                            onChange={updateText}
                            value={textContent}
                            className="digitization-note"
                            style={{
                                borderColor: '#e6e6e7',
                                borderRadius: '4px',
                                fontSize: 'smaller',
                                resize: 'none',
                                width: '100%',
                                padding:"15px",
                                direction: displayExtractedText ? getScriptDirection(sourceLanguage) : getScriptDirection(targetLanguage),
                            }}
                            placeholder= "Text content will appear here."
                        />

            </div>
            </div>
    )

}

DigitizationContentComponent.propTypes = {
    containerWidth: PropTypes.string,
    pageNumber: PropTypes.number,
    configWidth: PropTypes.string,
    updateConfigWidth: PropTypes.func,
    pages: PropTypes.array,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
};
export default DigitizationContentComponent;